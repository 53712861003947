import React from "react"
import Layout from "../components/Layout/Layout"
import Header from "../components/Layout/Header"
import { CiForkAndKnife } from "react-icons/ci"
import Seo from "../components/Layout/Seo"

export default function NotFond() {
  return (
    <Layout>
      <Header />
      <div className="max-w-screen-md m-auto py-40 min-h-screen">
        <h1 className="text-center flex items-center justify-center">
          <span className="text-3xl font-alphabet">
            404 : page not found...
          </span>
          <CiForkAndKnife size={40} />
        </h1>
        <p className="text-center py-10">
          お探しのページは見つかりませんでした。
        </p>
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="404 page not found." description={"お探しのページは見つかりませんでした。"} />
